import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Container } from "reactstrap"
import Head from "../components/head"
import shipping from "../images/about/shipping.jpg"
// import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

const Shipping = props => {
  return (
    <Layout>
      <Head
        title="Shipping"
        description="Orders normally ship by FedEx or USPS. For information about international shipping please contact us."
      />

      <Container>
        <section className="about-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner-section">
                  {/* <Img fluid={props.data.imageOne.childImageSharp.gatsbyImageData} alt="Shipping box" /> */}
                  <img
                    src={shipping}
                    className="img-fluid lazyload"
                    alt="Shipping box"
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <h4>Shipping within The Continental (Lower 48) United States</h4>
                <p>The following information applies to products other than those in our "Throw Your Name Around" custom imprinted products department and special order items.</p>
                <p>PLEASE NOTE: The following information is based on what would be expected in normal times, but of course we are not living in normal times. Delays in transit are possible and do happen. The carriers (USPS, FedEx, UPS) will not guarantee delivery times. We are NOT able to guarantee specific delivery times. PLEASE PLAN ON ADDITIONAL TIME IN TRANSIT, ESPECIALLY DURING THE HOLIDAY SEASON.</p>
                <p>For Standard Shipping we generally via USPS Priority Mail, USPS First Class Parcel, FedEx Ground or FedEx Home Delivery. Depending on your location Standard Shipping typically takes 1-5 business days for delivery within the continental U.S. from the time that we ship. (We generally ship within 1 business day of the time the order is received but it can take longer in some situations. If you have a specific time frame that you are working with please let us know.)</p>
                <p>For Economy Shipping we may use slower services such as FedEx SmartPost or Parcel Select through USPS.</p>
                <p>Sometimes an item will be out of stock when an order is placed. Should this happen, we will fill the order as soon as all items are in. If you are in a rush or need to know if an item is in stock, please contact us. We cannot guarantee that all items shown on the site will be in stock at all times.</p>
                <p>Please feel free to contact us if you need to have an item sent with Express or 2nd Day delivery or if you would like to check stock status of an item.</p>
                <h4>Shipping outside the continental United States</h4>
                <p>Shipping shown on our website is for shipping within the continental USA. If you are interested in having an order shipped to Alaska, Hawaii, territories or locations outside of the USA, please <Link to="/contact">contact us</Link> to inquire about additional shipping charges. Shipping charges shown on your order form will not be correct or honored. Please note that you are responsible for any duties, taxes, brokerage fees, etc., which result from shipping outside of the USA.</p>
                <h4>IF YOU WERE CHECKING OUT, PLEASE HIT BACK ON YOUR BROWSER TO RETURN TO YOUR SHOPPING CART.</h4>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </Layout>
  );
}

export default Shipping

export const pageQuery = graphql`{
  imageOne: file(relativePath: {eq: "about/shipping.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
}
`;
